import request from '@/utils/request'

// 视频列表
// @params { type : 视频分类 1-知识 2-教程}
export const queryVideoList = (params) => {
  return request('GET', '/app/video/video_list_home', params)
}

// 视频详情 idvideo的id
export const queryVideoDetail = (params) => {
  return request('GET', '/app/video/info', params)
}
