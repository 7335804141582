<template>
  <div class="video-list">
    <van-tabs line-width="0" v-model="active" @change="tabChange">
      <van-tab :title="item.title" v-for="(item,index) in tablist" :key="item.type">
        <template #title>
          <div class="video-title" :class="{'active': active === index}">
            <img v-if="active === index">
            <span>{{item.title}}</span>
          </div>
        </template>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="isLoading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onReachBottom"
          >
            <div class="video-all">
              <div class="total-video" v-for="(item,index) in  videoList" :key="index">
                <div class="video-div" @click="$gbUtils.handleToPage(`/video?id=${item.id}`)">
                  <div class="img-div">
                    <img :src="item.cover" class="video-img" />
                  </div>
                  <div class="video-text">
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { queryVideoList } from '../../services/video'
export default {
  name: 'videoList',
  data () {
    return {
      refreshing: false, // 下拉刷新状态
      isLoading: false, // 下拉刷新状态
      finished: false, // 触底加载状态
      pageNo: 1,
      pageSize: 8,
      pageTotal: 0,
      videoList: [], // 视频列表
      active: 0,
      type: 0 // 视频类型
    }
  },
  methods: {
    tabChange (e) {
      this.active = e
      console.log(e)
      this.pageNo = 1
      this.videoList = []
      this.getVideoList()
    },
    // 获取知识视频
    async getVideoList () {
      if (this.active === 0) {
        this.type = 1
      } else {
        this.type = 2
      }
      const resp = await queryVideoList({
        pageSize: this.pageSize,
        pageNumber: this.pageNo,
        type: this.type
      })
      if (!resp.success) return
      this.pageTotal = resp.result.total
      const videoList = resp.result.records
      this.videoList = this.pageNo === 1 ? videoList : this.videoList.concat(videoList)
    },
    onReachBottom () {
      this.finished = false
      this.isLoading = false
      this.refreshing = false
      console.log(this.isLoading, this.refreshing, this.finished)
      if (this.pageNo * this.pageSize < this.pageTotal) {
        this.pageNo++
        this.getVideoList()
      }
    },
    onRefresh () {
      this.loading = true
      this.refreshing = true
      this.pageNo = 1
      this.videoList = []
      this.getVideoList()
      this.loading = false
      this.refreshing = false
    }
  },
  computed: {
    tablist () {
      return this.$t('gameGuild.videoSetting')
    }
  },
  mounted () {
    this.getVideoList()
  }
}
</script>

<style  lang="scss" scoped>
.video-list {
  background-size: 100% 100%;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  .video-title {
    display: block;
    width: 100%;
    background-size: 100% 100%;
    color: #eac02d;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 44px;
    }
    &.active {
      color: #ffeeb4;
    }
  }
  .video-all {
    padding: 17px 7px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    // width: 100%;
    // display: flex;
    // flex-wrap: wrap;
    .video-div {
      width: 178px;
      height: 153px;
      background-size: 100% 100%;
      position: relative;
      margin-bottom: 10px;
      .img-div {
        position: absolute;
        margin: 6px;
        box-sizing: border-box;
        .video-img {
          width: 165px;
          height: 93px;
        }
      }
      .video-text {
        margin-top: 5px;
        width: 152px;
        position: absolute;
        top: 110px;
        left: 13px;
        font-size: 14px;
        color: #412a1e;
        overflow: hidden; /*超出部分隐藏*/
        white-space: nowrap; /*不换行*/
        text-overflow: ellipsis; /*超出部分文字以...显示*/
      }
    }
  }
}
</style>
<style lang="scss">
.video-list {
  .van-tab {
  }
}
</style>
